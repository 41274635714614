@media (min-width: 320px) {
  html{
    background-size: 98%;
  }
  }


html{
  background-image: url('/public/bremi.png');
  background-size: inial;
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: right bottom;
}


html.dark,
html.dark .navbar {
  background-color: rgb(28, 6, 33);
  background-blend-mode: soft-light;
}

.border-slate-200{
  border-color: #c5cfdd;
}


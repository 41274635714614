@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family:"dp";
  src:url("fonts/dark_poestry-webfont.ttf") format("truetype")
 } 

:root {
  --animation-speed: 1000ms;
  --animation-speed-fast: 250ms;
  --default-cell-bg-color: theme('colors.white');
  --default-cell-border-color: theme('colors.black');
  --default-cell-text-color: theme('colors.black');
  --absent-cell-bg-color: theme('colors.slate.400');
  --correct-cell-bg-color: theme('colors.green.400');
  --present-cell-bg-color: theme('colors.yellow.400');
}

.dark {
  --default-cell-bg-color: theme('colors.slate.900');
  --default-cell-border-color: theme('colors.white');
  --default-cell-text-color: theme('colors.white');
  --absent-cell-bg-color: theme('colors.slate.700');
}

.high-contrast {
  --correct-cell-bg-color: theme('colors.orange.400');
  --present-cell-bg-color: theme('colors.cyan.400');
}

.cell-fill-animation {
  animation: onTypeCell linear;
  animation-duration: 0.35s;
}

.cell-reveal {
  animation-duration: 0.35s;
  animation-timing-function: linear;
  animation-fill-mode: backwards;
}

.cell-reveal.absent {
  animation-name: revealAbsentCharCell;
}

.cell-reveal.correct {
  animation-name: revealCorrectCharCell;
}

.cell-reveal.present {
  animation-name: revealPresentCharCell;
}

.letter-container{
  font-family: 'dp', Arial, Helvetica, sans-serif;
}

.cell-reveal > .letter-container {
  animation: offsetLetterFlip 0.35s linear;
  animation-fill-mode: backwards;
}

svg.cursor-pointer {
  transition: all var(--animation-speed-fast);
}

svg.cursor-pointer:hover {
  transform: scale(1.2);
}

.jiggle {
  animation: jiggle linear;
  animation-duration: var(--animation-speed-fast);
}

.navbar {
  margin-bottom: 2%;
}

.navbar-content {
  display: flex;
  height: 3rem;
  align-items: center;
  justify-content: center;
}

.right-icons {
  display: flex;
  float: right;
  position: absolute;
  right: 10px;
  text-align: -moz-left;
}

.info{
  position: absolute;
  left: 10px;
  
}


.dark .dark\:text-gray-100,
.dark .dark\:text-gray-300{
  color:#ffffff !important;
}

.text-gray-500,
.text-gray-900{
  color:#000000 !important;
}

h3{
  font-weight: bold !important;
}

.bg-green-500{
  background-color: #44b03a;
}

.border-green-500{
  border-color: #44b03a;
}



@keyframes revealAbsentCharCell {
  0% {
    transform: rotateX(0deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50% {
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50.1% {
    background-color: var(--absent-cell-bg-color);
    border-color: var(--absent-cell-bg-color);
  }
  100% {
    transform: rotateX(180deg);
  }
}

@keyframes revealCorrectCharCell {
  0% {
    transform: rotateX(0deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50% {
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50.1% {
    background-color: var(--correct-cell-bg-color);
    border-color: var(--correct-cell-bg-color);
  }
  100% {
    transform: rotateX(180deg);
  }
}

@keyframes revealPresentCharCell {
  0% {
    transform: rotateX(0deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50% {
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50.1% {
    background-color: var(--present-cell-bg-color);
    border-color: var(--present-cell-bg-color);
  }
  100% {
    transform: rotateX(180deg);
  }
}

/* Additional animation on the child div to avoid letters turning upside down/snapping back to upright visual glitch */
@keyframes offsetLetterFlip {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(180deg);
  }
}

@keyframes onTypeCell {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.shadowed {
  /*text-shadow: 1px 1px 1px #000000;*/
}

.rounded{
  border-radius: 35px !important;
}

.key.rounded{
  border-radius: 5px !important;
  border: 2px solid #c5cfdd;
}

.dark .key.rounded{
  border: 0px;
}

.navbar-content > span.logo{
 
  font-size: 32px;
  font-family: 'dp', Arial, Helvetica, sans-serif;
  color:#662d91;
}

.navbar-content > span{
  font-size: 14px;
  font-family: 'dp', Arial, Helvetica, sans-serif;
}

.navbar{
  background-color: #ffffff;
}

.claim{
  font-size: 12px;
  font-family: 'dp', Arial, Helvetica, sans-serif;
  display: block;
  margin: 0 auto;
  color:#777777;
}

.info-rules{
  padding: 10px;
  border-left: 4px solid #fb923c;
  border-right: 4px solid #fb923c;
  margin: 10px;
  text-align: left;
  line-height: 18px !important;
  border-top: 2px solid #fb923c;
  border-bottom: 2px solid #fb923c;
  border-radius: 20px;
}

.text-sm {
  font-size: 0.84rem;
  line-height: 1.25rem;
}

.key.text-sm {
  font-size: 0.7rem;
  padding:4px;
}

a{
  border: 0px !important;
}

*, ::before, ::after{
  --tw-ring-color:#ffffff !important;
}
@keyframes jiggle {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-0.5rem, 0);
  }
  50% {
    transform: translate(0.5rem, 0);
  }
  75% {
    transform: translate(-0.5rem, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
